.numbers-dropdown {
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: none;
  width: 320px;
  box-sizing: border-box;
  background: var(--gray-accent);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.product-types {
  padding-left: 30px;
}

.numbers-dropdown .dropdown-toggle {
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.quantity {
  padding-left: 25px;
}

.payback {
  padding-left: 80px;
  padding-right: 80px;
}

.price-calc .quantity-input {
  /* -webkit-appearance: none;  */
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: none;
  width: 320px;
  box-sizing: border-box;
  background: var(--gray-accent);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.price-calc .dropdown-menu {
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  background: var(--gray-accent);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  transform: translate3d(0px, 0px, 0px) !important;
}

/* Blur the background of .dropdown-menu */
.price-calc .dropdown-menu::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  filter: blur(10px); 
  z-index: -1;
}

.price-calc .dropdown-menu .dropdown-item:hover {
  background: transparent;
}

.price-calc input[type='checkbox'] {
   background-color: black;
   border-color: black;
}

.dropdown-menu.show [x-placement="top-start"] {
  inset: auto auto 0px -10px !important;
}

.numbers-col {
  max-width: 384px;
  padding: 0 10px 10px 10px;
  height: 281px;
}

.numbers-container {
  height: 89px;
}

.payback p:nth-child(2) {
  width: 209px;
  text-align: center;
}

.numbers-col-wrapper {
  padding: 15px; /* This will give you a 30px gap between columns */
}

.numbers-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
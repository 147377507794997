/* 
s:	1t	2h	2t	3h	3t	4h	4t
h: 	12	16 	24	32	48	64	96
p:	8	12	16	24	32	48	64

hst + pst | psh
hsh + p(s-1)t
example:
	h2h 	+ 	p1t
	(16)	+	(8)
*/

.header-component {
  color: var(--primarytextcolor);
  font-family: var(--primaryfont);
  margin: 0;
  line-height: 1;
}

.h1 {
  font-size: 14px;
}

.h2 {
  font-size: 20px;
}

.h3 {
  font-size: 26px;
}

@media (max-width: 576px) {
  .h3 {
    font-size: 20px;
  }
}

.h4 {
  font-size: 40px;
}

@media (max-width: 576px) {
  .h4 {
    font-size: 26px;
  }
}

.h5 {
  font-size: 55px;
}

@media (max-width: 576px) {
  .h5 {
    font-size: 34px;
  }
}

.h6 {
  font-size: 70px;
}

.h7 {
  font-size: 106px;
}

/* Specials */
.logoheader {
  font-family: var(--primaryfont);
  font-weight: 700;
  font-size: 24px;
}

.hero-header {
  line-height: 140%;
}

.primary-color {
  color: var(--primary);
}

.secondary-color {
  color: var(--secondary);
}

.tertiary-color {
  color: var(--tertiary);
}

.primarytextcolor {
  color: var(--primarytextcolor);
}

.secondarytextcolor {
  color: var(--secondarytextcolor);
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.muted {
  color: var(--mutedtext);
}

.price-header {
  font-size: 36px;
}

@media (max-width: 576px) {
  .price-header {
    font-size: 26px;
  }
}

.footer-header {
  font-size: 30px;
}
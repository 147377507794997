.section{
  position:relative;
}

.section-center {
  text-align: center;
}
.background-div{
  width: 100vw;
  margin-left: 0;
  position:absolute;
  left:0;
  top:0;
  height: 100%;
  z-index: -1;
}
@media (min-width: 1200px) {
  .background-div {
    margin-left: calc(var(--bs-gutter-x) * -.5 - 3rem);
  }
}
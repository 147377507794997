.navbar {
  background: #FFF;
  min-height: 84px;
}

.navbar-logo {
  width: 45px;
  height: 45px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);  
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-left a {
  font-family: var(--primaryfont);
  text-decoration: none;
  font-size: 20px;
}

/* This can be used if the navbar should expand in increments */
/* @media (min-width: 1160px) {
  .custom-container {
    max-width: 1160px;
  }
} */

/* The navbar content sticks to the sides below 1160px */
.custom-container {
  max-width: 1160px;
}

#basic-navbar-nav {
  justify-content: flex-end;
  font-family: var(--primaryfont);
}

#basic-navbar-nav a {
  text-decoration: none;
  color: #000;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0px 0 0 0;
  font-size: 15px;
}

#basic-navbar-nav a:nth-child(2){
  margin-right: 40px;
}

@media (max-width: 991px) {
  #basic-navbar-nav a {
    padding: 0px 0 0px 0;
    margin-left: 0;
    font-size: 20px;
  }
}

.navbar-toggler:focus {
  outline: none!important;
  box-shadow: none!important;
}
.step-card {
  aspect-ratio: 488/468;
  width: fit-content;
  height: 468px;
}

.step-card-header {
  padding-top: 52px;
}

.step-card-header, .step-card-description {
  padding-left: 86px;
  padding-right: 86px;
}

.step-card-number {
  margin: 0;
}

.step-card-description {
  margin: 25px 0 0 0;
}

.step-card-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 768px) {
  .step-card-content {
    width: 100%;
    box-sizing: border-box;
  }
  .step-card {
    width: 100%;
    height: auto;
  }
  .step-card-description {
    padding-left: 25px;
    padding-right: 20px;
    padding-top: 0px;
  }

  .step-card-header {
    padding-left: 25px;
    padding-top: 20px;
  }

  .step-card-content {
    position: absolute;
  }
  .step-card.bell .step-card-content img{
    margin-top: 40px;
    margin-left: 40px;
  }
}

.step-card.bell .step-card-content img{
  margin-top: 20px;
  margin-left: 40px;
}

@media (min-width: 450px){
  .step-card.bell .step-card-content img{
    margin-top: 100px;
    margin-left: 40px;
  }
}

@media (min-width: 768px){
  .step-card.bell .step-card-content img{
    margin-top: 100px;
    margin-left: 90px;
  }
}


.integration-logo-wrap {
  height: 202px;
  width: 202px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
}

@media (max-width: 1249.98px) {
  .integration-row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0px;
  }

  .integration-logo-wrap {
    flex: 0 0 auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 1250px) {
  .integration-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}


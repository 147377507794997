.step-card-col > .step-card:not(:first-child) {
  margin-top: 200px;
}

.step-card:nth-child(4) .step-card-image {
  max-width: 240px;
  margin-left: 118px;
  margin-bottom: -90px;
}

.step-card:nth-child(4) .product-info {
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 440px) {
  .step-card:nth-child(3) .step-card-image {
    margin-top: -35px
  }

  .step-card:nth-child(4) .step-card-image {
    max-width: 200px;
  }
}


.sticky-left {
  padding: 100px 0px 0px 0;
}


@media (min-width: 1200px){
  .long {
    height: 2800px;
  }
  .sticky-left {
    padding: 100px 0px 100px 0;
    margin-bottom: 200px;
  }
}

.sticky-card {
  margin-bottom: 200px;
}

.sticky-left,
.sticky-card {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}



/* Make the sticky-card not transparent */
.sticky-card {
  background-color: white;
}

.sticky-card:not(:first-child) {
  box-shadow: none;
}

.step-card-col {
  position: relative;
}
.footer-image-container {
  display: inline-block;
  border-radius: 50%;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 62px;
  height: 62px;
}

.footer-header {
  display: inline-block;
}

.footer-icons a {
  color: black;
  text-decoration: none;
}

.footer-icons svg {
  height: 24px;
  width: 24px;
}

.footer-icons {
  display: flex;
  justify-content: flex-end;
}

.footer-divider {
  border-bottom: 1px solid #B1B1B1;
  margin: 10px 0;
}

.footer-links {
  padding-top: 35px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 20px;
}

.footer-links a {
  font-size: 16px;

}

.footer-links-col {
  box-sizing: border-box;
  flex: 1 0 100%;
  padding-bottom: 20px;
}

@media (min-width: 576px) { /* Adjust breakpoint as needed */
  .footer-links-col {
    flex: none;
    justify-content: space-between;
  }

}

@media (min-width: 768px) { /* Adjust breakpoint as needed */
  .footer-links-col {
    flex: 1 0 50%;
  }
}


@media (min-width: 992px) { /* Adjust breakpoint as needed */
  .footer-links-col {
    flex: none;
    justify-content: space-between;
  }
}
.collage-header {
  text-align: center;
}

.collage-item {
  position: relative;
  margin: 0;
}

.collage-col {
  margin: 0 10px;
}


.collage-item{ 
  border-radius: 23px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .collage-item {
    aspect-ratio: 1/1.3;
  }
}

.collage-item-text {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}
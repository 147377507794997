.section-wrapper.no-top {
  padding-top: 0px !important;
}
.section-wrapper.no-bottom {
  padding-bottom: 0px !important;
}

.section-wrapper.no-left {
  padding-left: 0px !important;
}

.section-wrapper.no-right {
  padding-right: 0px !important;
}
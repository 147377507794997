.contact-header-img {
	max-width: 488px;
	width: 100%;
}

.contact-hero-img {
	margin-right: 8px;
}

@media (min-width: 992px){
	.contact-hero-img {
		margin-right: -10px;
	}
}
@media (max-width: 800px){
	 
	.contact-hero-bicycle{
		left:-100px !important;
	}
	.contact-hero-chair{
		bottom:-100px !important;
		right:-100px !important;
	}
}
.contact-hero-bicycle{
	position: absolute;
	bottom:-100px;
	left:15px;
}
.contact-hero-chair{
	position: absolute;

	top:0px;
	right:60px;
}
.deco-imgs img{
	border-radius: 20px;
	border: #fff 12px solid;
	width: 162px;
	height: 130px;
	object-fit: none;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
	
  }
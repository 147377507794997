.mp-header a{
  text-decoration: none !important;
}

.mp-header a:hover{
  color: var(--mutedtext);
}

.mp-wrap a {
  text-decoration: underline;
}
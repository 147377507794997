.collage-item {
  display: flex;
  justify-content: center;
  max-width: 343px;
  max-height: 375px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.collage-item.middle {
  margin-left: 30px;
  margin-right: 30px;
}

.image-container {
  width: 100%;
  height: 375; /* larger height for larger screens */
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text-collage-item{
  background-color: #FBFAF8;
}
.text-collage-item img{
  width: 28px;
  height: 28px;
}
.image-container-text-collage{
  background-color: #FAFAFA;
  padding: 15px;
}

.white-circle {
  width: 11px;
  height: 11px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  margin-bottom: 2px;
}

.terms-container {
  border-radius: 24px;
  background: #fff;
  border: solid #EEEBE6 2px;
  z-index: 999;
}

.terms-row {
  max-width: 940px;
}

.terms-list {
  list-style-type: none;
  counter-reset: alpabetic-list;
  padding: 0;
}

.terms-list li {
  counter-increment: alpabetic-list;
}

.terms-list li::before {
  content: counter(alpabetic-list, lower-alpha) ")";
  margin-right: 5px;
}

@media (max-width: 800px){
	 
	.terms-hero-bicycle{
		left:-100px !important;
	}
	.terms-hero-chair{
		right:-100px !important;
	}
}
.terms-hero-bicycle{
	position: absolute;
	top:20px;
	left:15px;
}
.terms-hero-chair{
	position: absolute;
	top:150px;
	right:60px;
}
.deco-imgs img{
	border-radius: 20px;
	border: #fff 12px solid;
	width: 162px;
	height: 130px;
	object-fit: none;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
	
  }
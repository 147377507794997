.claims-form {  
    border-radius: 24px;
    background: #fff;
    border: solid #EEEBE6 2px;
    
}
.claims-inner-form {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.status-green {
    color: #3BA632;
}
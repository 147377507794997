.condition-info-outer {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -7.92448px -7.92448px 15.12794px 0px rgba(250, 250, 250, 0.50), 2.88151px 2.88151px 9.36491px 0px rgba(0, 0, 0, 0.20);
}
.condition-info-outer-expanded {
    border-radius: 16px;
}
.condition-info-inner {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: -7.92448px -7.92448px 15.12794px 0px rgba(250, 250, 250, 0.50), 2.88151px 2.88151px 9.36491px 0px rgba(0, 0, 0, 0.20);
}
.condition-download-pdf-button .pdf-download-img {
    height: 22px;
}
@media (max-width: 800px){
	 
	.condition-hero-bicycle{
		left:-100px !important;
	}
	.condition-hero-chair{
		right:-100px !important;
	}
}
.condition-hero-bicycle{
	position: absolute;
	top:20px;
	left:15px;
}
.condition-hero-chair{
	position: absolute;
	top:150px;
	right:60px;
}
.deco-imgs-condition img{
	border-radius: 20px;
	border: #fff 12px solid;
	width: 162px;
	height: 130px;
	object-fit: none;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
	
  }
  .external-link-img{
    width: 25px;
    height: 25px;
  }
  .hr-divider{
    background-color: #EAECF1;
    height: 1px;
  }
  .accordion-button:not(.collapsed){
    box-shadow: none;
  }
  .row-item{
    height: 80px;
    text-decoration: none;
  }
.kickback-card-wrapper {
  padding: 15px; /* To create a 30px gap between cards */
}

@media (min-width: 768px) and (max-width: 1199px) {
  .kickback-card-wrapper {
    max-width: calc(50% - 15px); /* Ensure the columns take up 50% of the width minus half the desired gap */
  }
}

.kickback-card-box {
  height:200px;
  padding: 20px 10px 10px 18px;
  border: #F2F0EC solid 2.5px;
  box-shadow: none;
  border-radius: 20px;
}

.kickback-card {
  padding: 10px;
 
}

.icon-container {
  height: 163px;
  display: flex;
}
.top-image{
  transform: translateY(-50%)  translateX(-50%);
  top:0;
  left:50%;
  background-color: #FAFAFA;

  
}
.top-image .inner{
  background-color: #fff;

}


@media (min-width: 768px) and (max-width: 1199px) {
  .white-card {
    height: 120px;

  }
}
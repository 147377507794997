.modal-content{
    background-color: rgba(255,221,221,0.94)  !important
}
.modal-header h4{
    color: #A73636;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 0 10px;
}
.modal-body, .modal-header{
    padding: 10px 15px 0 15px;
}
.modal-body p{
    color: #B05A5A;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.modal-content{
    border : 1px solid #DA0000;

}
.modal-dialog-centered{
    align-items: end;
}
.btn-close:focus{
    box-shadow:none;
}
.b-drop{
    background-color: transparent;
}
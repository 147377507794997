.hero-product {
  border-radius: 23px;
  background-color: rgba(230, 236, 234, 1);
  background: linear-gradient(to bottom, rgba(230, 236, 234, 1) 0%, rgba(231, 211, 213, 0.33) 100%);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 480px;
}

@media (min-width: 576px) {
  .hero-product {
      width: 488px;
      height: 468px;
  } 
}

.product-image-container {
  display: flex;
  justify-content: flex-end;
}

.product-image {
  max-width: 280px;
  width: 100%;
  height: auto;
  margin-bottom: -60px;
}

@media (min-width: 576px) {
  .product-image {
    max-width: 343px;
    width: 100%;
    height: auto;
  }
}

.product-info {
  background: rgba(255, 255, 255, 0.4); /* Semi-transparent white */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-bottom: 0px;
  padding: 35px 20px 20px 20px;
  border-radius: 23px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
}

@media (max-width: 576px) {
  .product-info {
    padding: 10px 15px;
    border-radius: 12px;
  }
  .product-info h1 {
    margin-bottom: 0px!important;
  }

  
}

.product-info h1 {
  color: var(--mutedtext);
  font-weight: 700;
}

.frankly-info {
  background-color: var(--gray-accent);
  /* background: linear-gradient(to bottom, rgba(230, 236, 234, 0.7) 0%, rgba(231, 211, 213, 0.2) 100%); */
  padding: 12px 5px 12px 10px;
  border-radius: 9px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 576px) {
  .frankly-info {
    padding: 10px;
  }
}

.frankly-info p {
  margin: 0;
}


.frankly-info a {
  text-decoration: underline;
}

.frankly-product-logo img {
  max-height: 17px;
}

.frankly-product-logo {
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);  
  border-radius: 50px;
  height: 31px;
  width: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-images{
  width: fit-content;
}
.hero-images.hero-img-one{
  right:0;
  top:-50px;
}
.hero-images.hero-img-two{
  left:0;
  top:80px;
}
.hero-images.hero-img-three{
  right:0;
  bottom:-50px;
}

@media (max-width: 1099px) {
  .hero-images.hero-img-one{
    right:-150px;
    top:-50px;
  }
  .hero-images.hero-img-two{
    left:-150px;
    top:280px;
  }
  .hero-images.hero-img-three{
    right:-150px;
    bottom:-120px;
  }
}
@media (max-width: 850px) {
  .margin-top-small{
   margin-top: 65px !important;
  }
  .text-margin{
    margin: 0 80px 0 80px;
  }
  .hero-images.hero-img-two{
    left:-250px;
    top:225px;
  }
  .hero-images.hero-img-three{
    right:-100px;
    bottom:-120px;
  }
}
.hero-images.hero-img-two-business{
  top:120px;
}
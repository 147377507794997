.claims-input-wrap {

}
.claims-input-label {
    font-size:16px;
    margin-bottom: 5px;
}
.claims-input {
    font-size:18px;
    height: 50px;
    padding: 15px 20px;
    width: 100%;
    border-radius: 25px;
    border:1px solid #C9CDD8;
}
.claims-input.error-state {
    border:1px solid #E31414;
}
.claims-input::placeholder {
    color: #9E9E9E;
}
.claims-input::-ms-input-placeholder {
    color: #9E9E9E;
}
.claims-input-wrap textarea {
    height:120px;
    resize: none;
    overflow-y: auto;
}

.claims-input[type="file"]::file-selector-button {
    display: none;
}
.claims-input[type="file"] {
    background:white;
}

/* ----------Toggle---------- */
.frankly-switch {
    position: relative;
    display: inline-block;
    min-width: 40px;
    height: 20px;
    margin: 0 5px 0 0!important; /* woocommerce adds margin to labels, we use important to trumph that*/
  }

  @media (min-width: 768px) {
    .frankly-switch {
      min-width: 52px;
      height: 28px;
      margin: 0 20px 0 0!important;
  }
}

.frankly-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.frankly-switch-container {
  display: flex;
  align-items: center;
}

.frankly-slider {
    position: absolute; 
    cursor: pointer; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #575757; /* Background color */
    -webkit-transition: .4s;
    transition: .4s;
    width: 36px;
}

@media (min-width: 768px) {
  .frankly-switch-container {
    margin-left: 13px;
  }
  .frankly-slider {
    width: 52px;
  }
}

/* This is the circle that indicates the state */
.frankly-slider:before {
    box-sizing: content-box;
    position: absolute;
    content: " ";
    height: 8px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #838383; /* Circle color */
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    text-align: center;
    padding:4px 0;
    font-size:8px
}

@media (min-width: 768px) {
  .frankly-slider:before {
    height: 14px;
    width: 22px;
    left: 3px;
    bottom: 3px;
  }
}

.frankly-radio-btn:checked + .frankly-slider {
    background-color: #575757;
}


.frankly-radio-btn:checked + .frankly-slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    content: url("../../../img/checkmark.svg");
    
    
    background-color: #5D9255; /* Circle color when checked */
  }
  
.frankly-slider {
    border-radius: 34px;
}
@media (min-width: 768px) {
  .frankly-radio-btn:checked + .frankly-slider:before {
    content: url("../../../img/checkmarkBig.svg");
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
    font-size: 13px; /* Checkmark size */
    
    line-height: 21px; /* Align the checkmark vertically */
  }
}
.slider-paragraph {
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
}
@media (min-width: 768px) {
    .slider-paragraph {
        line-height: 28px;
    }
}

.red-star {
  color: #E31414;
}
.accordion-button:focus {
  border: none;
  box-shadow: none;
}
.accordion-button:not(.collapsed){
  background-color: #fff;
}
.accordion-button:not(.collapsed)::after{
  background-image: var(--bs-accordion-btn-icon);
}
.accordion-item{
border: none;
}
.accordion-button{
  padding: 0px;
}
.accordion-body{
  padding: 0px;
}
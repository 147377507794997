.app-preview {
  border-radius: 23px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  height: 400px;
  width: 366px;
  background-color: rgba(230, 236, 234, 1);
  background: linear-gradient(to bottom, rgba(230, 236, 234, 1) 0%, rgba(231, 211, 213, 0.33) 100%);

  position: relative;
}

.app-preview .iphone2 {
  z-index: 1;
  position: absolute;
  top: -98px;
  left: 80px;
}

.app-preview .iphone1 {
  position: absolute;
  top: -20px;
  left: 0;
}
.mobile-app-screens-img img{
  width: 100%;
}


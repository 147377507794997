.partners-container {
  flex-wrap: wrap;
}

.partners-container img {
  margin-top: 20px;
}

@media (max-width: 576px) {
  .partners-container img {
    padding: 0 20px;
  }
}

/* ---------------- */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scroll-container {
    overflow: hidden;
    white-space: nowrap;
    /* width: 100%; */
}

.images-set {
    display: inline-block;
}

.images-set img {
    height: 60px;  /* Adjust according to your needs */
    width: auto;
    margin-right: 100px;  /* Spacing between images */

}

.images-set {
  display: inline-block;
  animation: scrolling 30s linear infinite;
  white-space: nowrap;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-400%);
  }
}
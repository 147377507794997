.button {
  border: none;
  color: var(--secondarytextcolor);
  background-color: #313131;
  border-radius: 999px;
  padding: 0 20px;
  height: 41px;
  font-family: var(--secondaryfont);
}

.button p {
  margin: 0;
  padding: 0;
  font-weight: 300;
}


.button.button-disabled {
  background-color: #9e9e9e;
  color: #313131;
  cursor: default;
}
.contact-form-container {
    border-radius: 24px;
    background: #fff;
    border: solid #EEEBE6 2px;
}
.contact-form-paragraph {
    line-height: 18px;
}
.vr-divider{
    background-color: #EAECF1;
    width: 1px;
    margin: 0px;
    height: 150px;
}
.hr-divider{
    background-color: #EAECF1;
    width: 100%;
    height: 1px;
    margin-top: 50px;
    margin-bottom: 50px;
}
@media (max-width: 991px){
	 
	.vr-divider{
        height: 1px !important;
        width: 150px !important;
        margin-top:30px;
        margin-bottom: 30px;
	} 
}
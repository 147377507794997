.different-image {
  height: 360px;
  object-fit:cover;
  width: 100% !important;
}


@media (min-width: 1200px) {
  .sticky-right {
    position: -webkit-sticky;
    position: sticky;
    top: 20vh;
  }

  .m-100 {
    margin-top: 500px;
  }
  
}
.text-background{
  background-color: #FBFAF8;
  border-radius: 30px;
  height: 360px;
  width: 100% !important;
  gap:20px;
}
.calendar-icon{
  width: 65px;
  height: 65px;

}
.deco-imgs.bicycle-img{
  top:150px;
}
.flow-col {
  border-radius: 23px;
  width: 100%;
  max-width: 344px;
  height: 280px;
  border: solid 5px #F2F0EC;
  background-color: #FAFAFA;
}
.middle{
  width: 100%;
  max-width: 344px;
}
.flow-col span{
  position:relative;
}
.flow-col .product-image{
  bottom:93px;
  left:-135px;
}
.frankly-shadow {
  box-shadow: -4.999626636505127px -4.999626636505127px 9.54434871673584px 0px rgba(250, 250, 250, 0.05), 1.8179712295532227px 1.8179712295532227px 5.9084062576293945px 0px rgba(0, 0, 0, 0.08);
  border-radius: 18px;
  margin-top: 30px;
}


.image-wrapper {

  border-radius: 10px;

}
.add-to-cart{
  position: relative;
  bottom:142px;
  left:10px;
}


/* .image-wrapper::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  pointer-events: none;
} */
.flow-row{
  display: flex;
  margin-bottom: 100px;
}

@media (max-width: 1199px) {
  .second-product-img{
    display: none;
  }
  .flow-row {
    gap:100px;
    flex-direction: column;
    align-items: center;
  }
  .checkout-price-img{
    top: -275px;
    right: -35px;
    width: 280px;
  }
  .flow-protection img {
    width:380px;
  }
  .flow-protection {
    width:385px !important;
  }
}
@media (min-width: 1200px) {
  .flow-row {
    display: flex;
    justify-content: center;
  }
  .middle {
    margin-left: 30px;
    margin-right: 30px;
  }
  .checkout-price-img{
    top: -290px;
    right: -45px;
    width: 320px;
  }
}

.flow-header {
  margin-top: 28px!important;
  padding-top: 0;
}

.flow-mobile-row {
  height: 2000px;
}

.flow-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  background-color: white;
}

.flow-sticky:not(:first-child) {
  margin-top: 200px;
}


.flow-sticky:not(:first-child) {
  box-shadow: none;
}

.flow-protection {
  top: -40px;
  width: 415px;
}

.flow-checkout {
  margin-top: 31px;
}

.number-text{
  color:#EFECE8; 
  font-size: 120px;
}

.second-product-img{
  border-radius: 20px;
  border: #fff 12px solid;
  width: 162px;
  height: 130px;
  margin-bottom: 20px;
  object-fit: none;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
.checkout-price-img{
  position: relative;
}
.checkout-product-image{
  position: relative;
  top:-90px;
  right:-75px;
  margin-bottom: 0px;
  
}
.checkout-product-image img{
  border-radius: 20px;
  border: #fff 12px solid;
  width: 162px;
  height: 130px;
  margin-bottom: 20px;
  object-fit: none;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
/* 
s:	1t	2h	2t	3h	3t	4h	4t
h: 	12	16 	24	32	48	64	96
p:	8	  12	16	24	32	48	64

hst + pst | psh
hsh + p(s-1)t
example:
	h2h 	+ 	p1t
	(16)	+	(8)
*/

.paragraph-component {
  color: var(--primarytextcolor);
  font-family: var(--secondaryfont);
}

.paragraph-component a {
  color: var(--primarytextcolor);
  text-decoration: none;
}

.p1 {
  font-size: 10px;
}

.p2 {
  font-size: 12px;
}

.p3 {
  font-size: 20px;
}

@media (max-width: 576px) {
  .p3 {
    font-size: 16px;
  }
}

.p4 {
  font-size: 26px;
}

@media (max-width: 576px) {
  .p4 {
    font-size: 20px;
  }
}

.p5 {
  font-size: 36px;
}

.p6 {
  font-size: 54px;
}

.p7 {
  font-size: 70px;
}

/* Specials */
.paragraph-hero {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin:10px;
  display: inline-block;
  /* color: yellow; */
}

.paragraph-muted {
  color:var(--mutedtext);
}

.paragraph-secondary-color {
  color:var(--secondarytextcolor);
}

.paragraph-secondary-color a {
  color:var(--secondarytextcolor);
  text-decoration: none;
}

.paragraph-bold {
  font-weight: 700;
}
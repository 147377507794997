.signup-container {
  background-color: rgba(230, 236, 234, 1);
  background: linear-gradient(to bottom, rgba(230, 236, 234, 1) 0%, rgba(231, 211, 213, 0.33) 100%);
  border-radius: 23px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.signup-text-input {
  border-radius: 60px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: solid #C9CDD8 1px;
  max-width: 100%;
  width: 100%;
  height: 61px;
  box-sizing: border-box;
  background-color: var(--gray-accent);
}


@media (min-width: 1200px) {
  .signup-text-input {
    width: 600px;
  }
}

.signup-text-input::placeholder {
  color:  #9E9E9E;
}

.signup-text-input:focus {
  outline: none;
}

.signup-checkbox-label a {
  color: var(--primary-color);
}

.signup-button {
  width: 139px!important;
  height: 48px!important;
  margin-top: 35px;
}

.signup-image {
  border-radius: 23px;
}
.deco-imgs{
  position: absolute;

}
.deco-imgs img{
  border-radius: 20px;
  border: #fff 12px solid;
  width: 162px;
  height: 130px;
  object-fit: none;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  
}
.deco-imgs.bicycle-img{
  top:50px;
  left:50px;

}
.deco-imgs.chair-img{
  right:8px;
  bottom:-10px;
}

@media (max-width: 850px) {
  .deco-imgs.chair-img {
    display: none !important;
  }
  .deco-imgs.bicycle-img{
    right:-40px !important;
  left:unset !important;
  top: -40px;
  
  }
  #signup{
    margin-top: 100px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* Defining colors for each page */
body {
  --primary: #000000;
  --secondary: #FFFFFF;
  --tertiary: #FAF3EE;
  /* --tertiary: #F6E6CC; */
  --background: white;
  --primarytextcolor:rgb(0, 0, 0);
  --mutedtext:#424242;
  --secondarytextcolor: rgb(255, 255, 255);

  /* --primaryfont: 'Spartan', sans-serif;
  --secondaryfont: 'Spartan', sans-serif; */
  --primaryfont: 'League Spartan', sans-serif;
  --secondaryfont: 'League Spartan', sans-serif;
  --gray-accent: rgba(255, 255, 255, 0.70);

}

/* Applying colors to general elements */
body {
  background-color: var(--background)!important;
}

h1, h2, h3, .h1 {
  color: var(--primarytextcolor);
  margin: 0;
  padding: 0;
}

* {
  font-family: var(--secondaryfont);
}

p {
  color: var(--primarytextcolor);
}

a, a:hover, a:visited {
  color: var(--primarytextcolor);
}
.disabled {
  text-decoration: line-through solid black 3px !important;
}
.disabled:hover {
  cursor: not-allowed;
}

.frankly-card {
  border-radius: 23px;
  background-color: rgba(230, 236, 234, 1);
  background: linear-gradient(to bottom, rgba(230, 236, 234, 1) 0%, rgba(231, 211, 213, 0.33) 100%);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.white-card {
  background: var(--gray-accent);
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.dist-30 > div:not(:first-child) {
  margin-left: 30px;
}

.dist-25 > div:not(:first-child) {
  margin-left: 25px;
}

.fw-medium {
  font-weight: 500!important;
}